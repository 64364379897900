import * as React from "react";
import { graphql } from "gatsby";

import Hero from "../../components/sales-booking/hero";
import Layout from "../../components/shared/layout-light";
import callSvg from "../../images/sales-booking/call.svg";
import connectSvg from "../../images/sales-booking/connect.svg";
import calendarSvg from "../../images/sales-booking/calendar.svg";
import * as Analytics from "../../utils/analytics";

import RTB from "../../components/shared/rtb";
import CalendlyWidget from "../../components/sales-booking/calendly-widget";

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "sales-booking/hero.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 1240) {
          ...GatsbyImageSharpSizes
        }
      }
    }
  }
`;

const CALENDLY_URL =
  "https://calendly.com/d/yyt-58s-x76/connect-with-an-outer-product-specialist?primary_color=0a4683";

function handleCallPress() {
  Analytics.track("Call Outer", { location: "Sales Booking Lander" });
}

const RTB_DATA = [
  {
    image: calendarSvg,
    title: "Book your appointment below",
    text: "Find a day and time that works for you and book your appointment.",
  },
  {
    image: connectSvg,
    title: "Choose how you want to connect",
    text: "Choose to connect an Outer Product Specialist via Zoom (video call) or phone call.",
  },
  {
    image: callSvg,
    title: "Want to connect right now?",
    text: (
      <span>
        Our team is standing by to help. Give us a call at:{" "}
        <a
          href="tel:855-277-7744"
          onClick={handleCallPress}
          className="bold underline"
        >
          855-277-7744
        </a>
      </span>
    ),
  },
];

const SalesBookingPage = function ({ data }) {
  return (
    <Layout homeUrl="https://liveouter.com">
      <Hero data={data} />
      <RTB data={RTB_DATA} />
      <div className="mt-20">
        <CalendlyWidget calendlyUrl={CALENDLY_URL} />
      </div>
    </Layout>
  );
};

export default SalesBookingPage;
