import Img from "gatsby-image";
import React from "react";

import arrowDownSvg from "../../images/darkmode/arrow-down.svg";

export default function Hero({ data }) {
  return (
    <div className="relative text-center text-blue-900 lg:text-white-900">
      <Img
        className="darken-image"
        fluid={data.hero.childImageSharp.sizes}
        alt="Try an Outer Sofa at home"
      />

      <div className="mt-7 lg:mt-0 lg:absolute lg:-inset-0 lg:flex lg:flex-col">
        <div className="content-container">
          <h1 className="text-4xl lg:text-5xl xl:text-7xl lg:mt-12 2xl:mt-40">
            Connect with an Outer Product Specialist
          </h1>
          <div className="lg:w-7/12 lg:mx-auto">
            <p className="text-lg mt-4 mb-6 leading-snug md:mt-7 md:mb-12 lg:tracking-wide lg:text-2xl">
              Connect with an Outer Product Specialist via live video or phone
              call where you’ll have an opportunity to learn more about Outer’s
              products and get answers to any questions you may have.
            </p>
          </div>
          <div className="hidden sm:inline-block text-center mx-auto">
            <img src={arrowDownSvg} />
          </div>
        </div>
      </div>
    </div>
  );
}
