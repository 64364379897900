import React from "react";
import useScript from "./useScript";
import "./calendly.scss";

export default function CalendlyWidget({ calendlyUrl }) {
  useScript("https://assets.calendly.com/assets/external/widget.js");
  return (
    <div
      className="calendar-widget calendly-inline-widget w-full"
      data-url={calendlyUrl}
    />
  );
}
